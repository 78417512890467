import axios from "axios"
import { sendEmail } from "../../../services/sendEmail"
import {
  sendToZendesk,
  zendeskUploadFiles,
  generateUploadToken,
  generateUploadUrl,
} from "../../../services/zendeskService"
import { enrollmentValidationSchema } from "../utils/enrollmentValidationSchema"
import firebase from "firebase"

import { getSignedInUser } from "../../Auth/services/user"
import { checkIfUserAlreadyExists } from "../../Auth/services/signup"
// import { sendMessage } from "../../../services/telerivet"
import {
  GATSBY_WEBSITE_URL,
  GATSBY_FIREBASE_PROJECT_ID,
  GATSBY_FIREBASE_PATIENT_ROLE_ID,
  GATSBY_AWS_SES_EMAIL_TO,
  GATSBY_AWS_SES_EMAIL_FROM,
  GATSBY_AWS_SES_EMAIL_BCC,
  GATSBY_ENV,
  GATSBY_MAKE_ENROLLMENT_WEBHOOK_URL,
  GATSBY_SOFTWARE_NAME,
} from "gatsby-env-variables"
import { getUserAddresses, getUserData } from "../../Auth/services/signin"
import { dateConverter } from "../EnrollmentSummary/services/dateConverter"

export const shouldPatientButtonBeDisabled = ({ formData, isPersonal }) => {
  if (formData?.agreeToConsent?.length < 1) return true
  else {
    let isFormValid
    isFormValid = enrollmentValidationSchema(isPersonal)
      .isValid({
        ...formData,
      })
      .then((valid) => {
        isFormValid = valid
        return isFormValid
      })

    return !isFormValid
  }
}

const formatName = (firstName, lastName, middleInitial) => {
  const MI = middleInitial ? ` ${middleInitial} ` : " "
  return `${firstName}${MI}${lastName}`
}

const capitalize = (string) => {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
}

export const sendEnrollmentForm = async (config) => {
  let { errorCallback, callback, enrollmentData, ids } = config
  let {
    firstName,
    lastName,
    gender,
    birthday,
    mobileNumber,
    department,
    employeeNumber,
    startDate,
    site,
    personalEmail,
    workEmail,
    emergencyContactName,
    emergencyContactNumber,
    emergencyContactEmail,
    emergencyContactRelationship,
  } = enrollmentData

  let emergencyContact = {
    name: emergencyContactName,
    mobileNumber: emergencyContactNumber,
    email: emergencyContactEmail,
    relationship: emergencyContactRelationship,
  }

  // Zendesk image
  try {
    if (ids?.length > 0) {
      let uploadedFiles = await zendeskUploadFiles(ids)
      let generatedFileTokens = await generateUploadToken(uploadedFiles)
      let generatedFileUrls = await generateUploadUrl(uploadedFiles)

      enrollmentData.generatedFileTokens = await generatedFileTokens
      enrollmentData.generatedFileUrls = await generatedFileUrls
    }
  } catch (error) {
    console.log(error)
    if (errorCallback) errorCallback()
    return
  }

  try {
    let { authUser, addresses, userData } = getSignedInUser()

    let email = authUser?.email
    let authUid = authUser?.uid
    let addressesId = addresses?.id || ""
    let addressesList = addresses?.addresses || []
    let userRoles =
      userData?.roles?.filter(
        (role) => role.subdomain !== GATSBY_WEBSITE_URL
      ) || []

    let userAllowedSubdomains =
      userData?.allowedSubdomains?.filter(
        (subdomain) => subdomain !== GATSBY_WEBSITE_URL
      ) || []

    if (!authUid) {
      userData = await checkIfUserAlreadyExists(authUser.email)
      authUid = userData?.authUid
    }
    // Add  address details
    let addressInformation = {
      primary: true,
      type: enrollmentData?.address?.addressType?.toLowerCase(),
      streetAddress: enrollmentData?.address?.streetAddress,
      barangay: enrollmentData?.address?.barangay?.value,
      city: enrollmentData?.address?.city?.value,
      province: enrollmentData?.address?.province?.value,
    }
    let finalAddressList = [...addressesList, { ...addressInformation }]

    let address = await firebase
      .firestore()
      .collection("addresses")
      .add({ addresses: [...finalAddressList] })
    addressesId = address.id
    // if (!addressesId) {
    //   let address = await firebase
    //     .firestore()
    //     .collection("addresses")
    //     .add({ addresses: [...finalAddressList] })
    //   addressesId = address.id
    // } else {
    //   await firebase
    //     .firestore()
    //     .collection("addresses")
    //     .doc(addressesId)
    //     .update({ addresses: [...finalAddressList] })
    // }

    let convertedBirthday = dateConverter(birthday)

    const USER_DOCUMENT = {
      firstName: firstName || "",
      lastName: lastName || "",
      mobileNumber: mobileNumber || "",
      email: email || "",
      addresses: addressesId,
      birthday,
      gender: gender || "",
      emergencyContact: { ...emergencyContact },
      employeeNumber: employeeNumber,
      department: department,
      site: site,
      personalEmail: personalEmail || email,
      workEmail: workEmail || "",
      startDate: startDate || {},
      roles: [
        ...userRoles,
        {
          status: "active",
          subdomain: GATSBY_WEBSITE_URL,
          role: GATSBY_FIREBASE_PATIENT_ROLE_ID,
          projectId: GATSBY_FIREBASE_PROJECT_ID,
        },
      ],
      allowedSubdomains: [...userAllowedSubdomains, GATSBY_WEBSITE_URL],
      companyId: enrollmentData.generatedFileTokens || [],
    }

    // User data will always be null

    let user
    if (userData?.id) {
      await firebase
        .firestore()
        .collection("users")
        .doc(userData?.id)
        .update({ ...USER_DOCUMENT, authUid })
    } else {
      user = await firebase
        .firestore()
        .collection("users")
        .add({ ...USER_DOCUMENT, authUid })

      emergencyContact.relationship = emergencyContact.relationship.value

      axios.post(GATSBY_MAKE_ENROLLMENT_WEBHOOK_URL, {
        ...USER_DOCUMENT,
        emergencyContact,
        address: { ...addressInformation },
        birthday: convertedBirthday,
        startDate: dateConverter(startDate),
        website: GATSBY_SOFTWARE_NAME,
        site: site.value || site,
        firebaseUserId: user.id,
        environment: GATSBY_ENV === "production" ? GATSBY_ENV : "develop",
      })
    }

    // Add email to employee object
    enrollmentData.email = email

    await sendEnrollmentFormToZendesk({
      config,
      document: { ...USER_DOCUMENT, authUid },
    })

    // await sendEnrollmentFormToEmail(config)
    // await sendMessage({
    //   recipient: employee?.mobileNumber,
    //   message: textMessageTemplate(GATSBY_ENV),
    // })

    let newUserData = await getUserData({ authUid })
    let userAddresses = await getUserAddresses({ addressesId })

    if (callback) callback(newUserData, userAddresses)
  } catch (error) {
    console.log(error)
    if (errorCallback) errorCallback()
  }
}

export const sendEnrollmentFormToEmail = (config) => {
  let { enrollmentData, emailTemplate, type } = config
  const { firstName, lastName, middleInitial, email } = enrollmentData.employee
  const fullname = formatName(firstName, lastName, middleInitial)

  const subject = `Alorica Hear+ Program: ${capitalize(
    type
  )} Enrollment for ${fullname} has been ${
    type === "doctor" ? "Approved" : "Received"
  }`
  return sendEmail({
    to: GATSBY_AWS_SES_EMAIL_TO,
    cc: email,
    from: GATSBY_AWS_SES_EMAIL_FROM,
    subject,
    html: emailTemplate(enrollmentData),
    bcc: GATSBY_AWS_SES_EMAIL_BCC,
  })
}

export const sendEnrollmentFormToZendesk = ({ config, document }) => {
  let { enrollmentData, ticketTemplate, type } = config
  let isTest = GATSBY_ENV !== "production"

  let tags = [
    `${type}_enrollment`,
    "alorica",
    "alorica_mh",
    "alorica_enrollment",
  ]
  if (isTest) tags.push("test")

  const { firstName, lastName, middleInitial, email } = enrollmentData
  const fullname = formatName(firstName, lastName, middleInitial)

  let subject = `${isTest ? "[TEST] " : ""}Alorica Hear+ ${capitalize(
    type
  )} Enrollment Form of ${fullname}`

  let customFields = []
  const ZENDESK_JSON_FIELD_ID = 900008616066

  customFields.push({
    id: ZENDESK_JSON_FIELD_ID,
    value: JSON.stringify(document),
  })

  let requestBody = {
    type: "request",
    tags,
    subject,
    requester: {
      name: fullname,
      email: email,
    },
    comment: {
      body: ticketTemplate(enrollmentData),
    },
    custom_fields: customFields,
  }

  if (enrollmentData?.generatedFileTokens?.length > 0) {
    requestBody.comment.uploads = [...enrollmentData.generatedFileTokens]
    tags.push("alorica_unverified")
  }

  return sendToZendesk(requestBody, [])
}
